import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import ResponsiveAppBar from "../components/ResponsiveAppBar"
import DefaultPage from "../components/DefaultPage"

const Support = () => (
  <>
    <SEO
      title="Support"
      keywords="Vacation home Real estate investing, is real estate investing worth it, is real estate investing a business,how to start real estate investing"
    />
    <ResponsiveAppBar />
    <Body>
      <DefaultPage
        title="Cloud Castles Support"
        subtitle="Please email support@cloudcastles.io"
        body=""
      />
    </Body>
  </>
)

export default Support

const Body = styled.div`
  position: relative;
`
